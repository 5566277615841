<template>
  <div>
    <p class="error">{{ error }}</p>
    <!--错误信息-->
    <button @click="saoyisao" style="color: red;padding: 10px;">扫一扫</button>
    <p class="decode-result">
      扫描结果:
      <!-- <input type="text" :value="result"> -->
    </p>
    <textarea name="" id="" cols="30" rows="10">{{ result }}</textarea>
    <!--扫描结果-->
  </div>
    
</template>

<script>

export default {
  // 注册
  components: {  },
  data() {
    return {
      result: '', // 扫码结果信息
      error: '' // 错误信息
    }
  },
  methods: {
    saoyisao(){
      // console.log(123)
      // this.$router.replace('/saoyisao')
      this.$router.push('/saoyisao')
    }
  },
  created() {
    
  },
  activated() {
    this.result = this.$route.query.result;
  },
}
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}

</style>
